import { useTracking } from 'react-tracking';
import { Modal, Text, useMount } from '@simplywallst/ui-core';
import { useSubscriptionRecovery } from '@/hooks/useSubscriptionRecovery';
import { useModalManagement } from '@/components/ModalManagement';
import { useEffect } from 'react';
import { useStripePortal } from '@/hooks/useStripePortal';

function SubscriptionRecoveryModal({ onClose }: { onClose: () => void }) {
  const { trackEvent } = useTracking();
  const { planName, planInterval } = useSubscriptionRecovery();
  const { data: stripePortal } = useStripePortal();

  useMount(() => {
    trackEvent({
      action: 'shown',
      subject: 'subscriptionRecovery',
    });
  });

  return (
    <Modal
      blurTargetId={'root'}
      removeWindowScroll
      title="Action required"
      onClose={onClose}
      primaryLabel="Update payment details"
      onPrimaryClick={() => {
        trackEvent({
          action: 'click',
          modifier: 'updatePaymentDetails',
        });
        window.open(stripePortal?.url, '_blank');
        onClose();
      }}
    >
      <Text color="spaceGray">
        Your renewal payment for the{' '}
        <Text as="span" color="spaceGray" typography="strong">
          {planName} {planInterval} subscription
        </Text>{' '}
        recently failed. Your account is temporarily downgraded to the free
        plan. Please update your payment method to successfully renew and enjoy
        your {planName} {planInterval} subscription.
      </Text>
    </Modal>
  );
}

const MODAL_ID = 'subscriptionRecoveryModal';

export function SubscriptionRecovery() {
  const { isShowingModal, addModalIfEmpty, removeModal } =
    useModalManagement(MODAL_ID);

  const { status, isPastDue } = useSubscriptionRecovery();

  const { Track } = useTracking({
    type: 'track',
    page: 'subscriptionRecovery',
  });

  useEffect(() => {
    if (status === 'success' && isPastDue) {
      addModalIfEmpty();
    }
  }, [isPastDue, status, addModalIfEmpty]);

  if (isShowingModal) {
    return (
      <Track>
        <SubscriptionRecoveryModal
          onClose={() => {
            removeModal();
          }}
        />
      </Track>
    );
  }
  return null;
}
