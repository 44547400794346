import { useQuery } from '@tanstack/react-query';

import { queryFn } from './queryFn';
import useUser from '../useUser';

export const useStripePortal = (planId?: string) => {
  const { data: user } = useUser();

  return useQuery({
    queryKey: ['STRIPE_PORTAL', planId],
    queryFn,
    staleTime: 600000,
    // TODO: The RUNTIME_ENV condition needs to be lifted to a standardised consumer
    // Having the condition here is a code smell and hides its usage
    // However, the current plans integration doesn't fully separate native plan flows from web plan (stripe) flows
    // The plans integration should be refactored to separate these concerns, where stripe isn't used in native
    enabled: user.loggedIn && RUNTIME_ENV !== 'native',
  });
};
