import { useMemo } from 'react';
import { getPlanById } from './helpers/getPlanById';
import { useUserPlans } from '../';

type Args = {
  planId: string;
};

export const usePlanById = ({ planId }: Args) => {
  const { plans, status } = useUserPlans();

  const userPlan = useMemo(() => {
    if (status === 'success' && typeof plans !== 'undefined') {
      return getPlanById(plans, planId);
    }
  }, [plans, planId, status]);

  return {
    status,
    data: userPlan,
  };
};
