import useUser from '@/hooks/useUser';
import { getPlans } from '@simplywallst/services';
import type { Plans } from './types';
import { useQuery } from '@tanstack/react-query';
import { withAuth } from '@/utilities/queries';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useUserAccess } from '../useUserAccess';
import type { PlanType } from '@simplywallst/services';
import { useSnackbar } from '@/features/snackbar';
import { useEffect } from 'react';
type Response = Awaited<ReturnType<typeof getPlans>>['data'];
const sanitizePlansData = (plans: Response): Plans => {
  return plans.map((plan) => ({
    [plan.id]: {
      id: plan.id,
      name: plan.metadata.display_name,
      amount: plan.amount / 100,
      interval: plan.interval,
      currencyISO: plan.currency,
      canTrial: plan.trial_days > 0,
      type: plan.plan_type,
      visible: plan.metadata.visible,
      uacCompaniesAnalysis: plan.metadata.uac_companies_analysis,
      features: {
        institutionalQualityData:
          plan.metadata.feature_institutional_quality_data,
        globalMarkets: plan.metadata.feature_global_markets,
        companyReportsPerMonth: plan.metadata.feature_company_reports_per_month,
        watchlist: plan.metadata.feature_watchlist,
        companiesAnalysedInPortfolio:
          plan.metadata.feature_companies_analysed_in_portfolio,
        totalPortfolios: plan.metadata.feature_total_portfolios,
        stockScreener: plan.metadata.feature_stock_screener,
        exportToPdf: plan.metadata.feature_export_to_pdf,
        dataAudit: plan.metadata.feature_data_audit,
        stockIdeaViews: plan.metadata.feature_stock_idea_views,
      },
    },
  }));
};

export async function queryFn(
  context: QueryFunctionContext<[string, boolean, string, PlanType]>
) {
  const { 1: isLoggedIn, 2: planId } = context.queryKey;
  let plansData: Plans;
  if (isLoggedIn) {
    const plansResponse = await withAuth(getPlans);
    plansData = sanitizePlansData(plansResponse.data);
  } else {
    const plansResponse = await getPlans();
    plansData = sanitizePlansData(plansResponse.data);
  }
  const userPlan = plansData.find((plan) => plan[planId]);

  return {
    plansData,
    userPlanName: userPlan !== undefined ? userPlan[planId].name : '',
    userPlanType: userPlan !== undefined ? userPlan[planId].type : '',
  };
}

export function getQueryKey(
  loggedIn = false,
  plan = '',
  planType: PlanType = ''
): [string, boolean, string, PlanType] {
  return ['PLANS_DATA', loggedIn, plan, planType];
}

export function useUserPlans() {
  const { addSnackbar } = useSnackbar();
  const { data: userData, isFetched: isUserFetched } = useUser();
  const { data: userAccess, status: userAccessFetchStatus } = useUserAccess();

  const { data, status, error } = useQuery({
    queryKey: getQueryKey(
      userData.loggedIn,
      userData.billing.plan,
      userAccess.planType
    ),
    queryFn,
    staleTime: Infinity,
    enabled: isUserFetched && userAccessFetchStatus === 'success',
  });

  useEffect(() => {
    if (status === 'error') {
      console.log('is this getting called multiple times?');
      const message =
        error instanceof Error
          ? error.message
          : 'An unknown error has occurred';

      addSnackbar({
        type: 'negative',
        lifeTime: 5000,
        message,
        actionLabel: 'Ok',
      });
    }
  }, [error, status]);

  if (status === 'success') {
    return {
      status,
      plans: data.plansData,
      // TODO - deprecate below: is related to User's Plan, rather than Plans
      name: data.userPlanName,
      type: data.userPlanType,
    };
  }

  if (status === 'error') {
    return { status, error };
  }

  return { status };
}
