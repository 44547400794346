import { post } from "../methods";
function getStripePortalUrl(req, headers = {}) {
  const { returnUrl, planId } = req;
  const url = planId ? `portal/${planId}?return_url=${returnUrl}` : `portal?return_url=${returnUrl}`;
  return post({
    url,
    headers,
    baseURL: "https://billing.simplywall.st/"
  });
}
export {
  getStripePortalUrl as default
};
