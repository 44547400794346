import { get } from "../methods";
function getPlans(headers = {}) {
  return get({
    url: `v2/plans`,
    headers,
    baseURL: "https://billing.simplywall.st/"
  });
}
export {
  getPlans as default
};
