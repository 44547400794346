import type { Plans, Plan } from '../../';

export const getPlanById = (plans: Plans, planId: string): Plan | undefined => {
  if (plans.length) {
    const record = plans.find((plan) => {
      return plan[planId];
    });
    if (typeof record !== 'undefined') {
      return Object.values(record)[0];
    }
  }
  return;
};
