import { withAuth } from '@/utilities/queries';
import { getStripePortalUrl } from '@simplywallst/services';
import type { QueryFunctionContext } from '@tanstack/react-query';
export async function queryFn({
  queryKey: [_key, planId],
}: QueryFunctionContext<[string, string | undefined]>) {
  const returnUrl =
    NODE_ENV === 'development' || window.location.href.includes('localhost')
      ? 'https://simplywall.st/plans'
      : window.location.origin + window.location.pathname;

  const response = await withAuth(getStripePortalUrl, {
    returnUrl,
    planId,
  });
  return response.data;
}
