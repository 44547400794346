import { useMemo } from 'react';
import useUser from './useUser';
import { useUserPlans } from './useUserPlans';

/**
 * Custom React hook for managing subscription recovery.
 *
 * This hook uses `useUserPlans`, `useUser`, and `useStripePortal` hooks to get the necessary data.
 * It then processes this data and returns an object with the following properties:
 * - `status`: react-query fetching status
 * - `planName`: The name of the user's plan.
 * - `planInterval`: The interval of the user's plan, which can be 'yearly', 'monthly', or the original interval string.
 * - `isPastDue`: A boolean indicating whether the user's billing status is 'past_due'.
 *
 * @returns {Object} An object containing `status`, `planName`, `planInterval`, and `isPastDue`.
 */
export function useSubscriptionRecovery() {
  const { status, name: planName, plans } = useUserPlans();
  const { data: user } = useUser();
  const { plan } = user.billing;

  const planInterval = useMemo(() => {
    let planInterval = '';
    if (typeof plans !== 'undefined') {
      plans.forEach((p) => {
        Object.values(p).forEach((value) => {
          if (value.id === plan) {
            planInterval = value.interval;
          }
        });
      });
    }
    if (planInterval.toLowerCase() === 'year') {
      return 'yearly';
    }
    if (planInterval.toLowerCase() === 'month') {
      return 'monthly';
    }
    return planInterval;
  }, [plans, plan]);

  return {
    status,
    planName,
    planInterval,
    isPastDue: user.billing.status === 'past_due',
  };
}
